<template>
  <ul class="main-nav">
    <li
      v-bind:class="{
        active: currentPath == 'index' || currentPath == 'index',
      }"
    >
      <router-link to="/">홈으로</router-link>
    </li>
    <li
      v-bind:class="{
        active:
          currentPath == 'courselistindex' || currentPath == 'courselistindex',
      }"
    >
      <router-link to="/courselistindex">강의 목록</router-link>
    </li>
    <li
      v-bind:class="{
        active:
          currentPath == 'productlistindex' || currentPath == 'productlistindex',
      }"
    >
      <router-link to="/productlistindex">모델 스토어</router-link>
    </li>
    <!-- <li
      v-if="currentUser && currentUser.is_superuser"
      v-bind:class="{
        active:
          currentPath == 'dataset-category' || currentPath == 'dataset-category',
      }"
    >
      <router-link to="/dataset-category">데이터 스토어</router-link>
    </li> -->
    <li
      v-bind:class="{
        active:
          currentPath == 'blog-list' || currentPath == 'blog-list',
      }"
    >
      <router-link to="/blog-list">블로그</router-link>
    </li>
    <li>
      <a href="https://www.youtube.com/playlist?list=PLhZpGghaa_0QhRZBk6ALlTDM5MlM66Hqj" target="_blank"
        >유튜브 채널</a>
    </li>
    <div class="main-nav menu-mobile" v-if="!currentUser">
      <li
        v-bind:class="{
          active: currentPath == 'login' || currentPath == 'login',
        }"
      >
        <router-link to="/login">로그인 / 회원가입</router-link>
      </li>
    </div>
    <div class="main-nav menu-mobile" v-else>
      <li
        v-bind:class="{
          active:
            currentPath == 'course-student' || currentPath == 'course-student',
        }"
      >
        <router-link to="/course-student">내 강의</router-link>
      </li>
      <li
        v-bind:class="{
          active:
            currentPath == 'product-student' || currentPath == 'product-student',
        }"
      >
        <router-link to="/product-student">내 모델</router-link>
      </li>
      <!-- <li
        v-bind:class="{
          active:
            currentPath == 'course-message' || currentPath == 'course-message',
        }"
      >
        <router-link to="/course-message">ExLM 에이전트</router-link>
      </li> -->
      <li
        v-bind:class="{
          active:
            currentPath == 'setting-edit-profile' || currentPath == 'setting-edit-profile',
        }"
      >
        <router-link to="/setting-edit-profile">계정 설정</router-link>
      </li>
      <li>
        <router-link to="/register" @click.prevent="logOut"
          >로그아웃</router-link
        >
      </li>
    </div>
  </ul>
</template>
<script>
import Vue from "vue";
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {
    currentUser() {
      return this.$store.state.auth.user?.user;
    },
    currentPath() {
      return this.$route.name;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>
