<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        
        <layoutsloginborder></layoutsloginborder>
       <privacypolicy></privacypolicy>
            <!-- Help Details -->
			<div class="page-content">
				
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="terms-content">
								<div class="terms-text">
									<p>(주) 한국인공지능아카데미는 (이하 ‘회사‘는) 고객님의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호“에 관한 법률을 준수하고 있습니다. 회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</p>
									<p>이 개인정보취급방침은 개인정보와 관련한 법령 또는 지침의 변경이 있는 경우 갱신되고, 정책의 변화에 따라 달라질 수 있으니 이용자께서는 exml.kr 사이트를 방문할 시 수시로 확인하여 주시기 바랍니다. 회사의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.</p>
									<h5>1. 수집하는 개인정보의 항목</h5>
									<p>회사는 회원가입, 서비스 제공 등을 위해 아래와 같은 개인정보를 수집하고 있습니다. 1) 필수 수집항목 : 성명, 휴대폰 전화번호, 아이디(이메일 주소), 비밀번호 2) 선택 수집항목 : 생년월일, 성별, 직업/학과, 관심사, SNS 및 Github 정보 등 3) 개인정보 수집방법 : 홈페이지 (회원가입과 설문조사 등)</p>

									<h5>2. 개인정보의 수집 및 이용목적</h5>
									<p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 : 콘텐츠 제공, 구매 및 요금 결제 2) 회원 관리 : 회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 불만처리 등 민원처리, 고지사항 전달, 맞춤형 수업, 수업 콘텐츠 개발에 활용 3) 마케팅 및 광고에 활용 : 신규 서비스(제품) 개발 및 특화, 이벤트 등 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 4) 그 외 서비스 이용과정에서 IP Address, 쿠키, 접속 로그, 서비스 이용기록, 결제 기록, 이용정지 기록이 자동으로 수집될 수 있습니다. 5) “가입하기 버튼을 클릭하면 약관에 동의하며 쿠키 사용을 포함한 데이터 정책을 읽고 이해하신 것으로 간주됩니다.“라는 문구 하에, 회사는 이용자가 사이트 가입 시 개인정보보호정책과 이용약관의 각각의 내용에 동의 한 것으로 봅니다.</p>
									<h5>3. 개인정보의 보유 및 이용기간</h5>
									<p>개인정보의 처리 및 보유기간은 다음과 같습니다.</p>
									<p>보유항목 : 쿠키, 세션 등 처리항목과 같습니다.
보유근거 : 이용약관 및 개인정보처리방침
보유기간 : 이용계약에 따른 회원자격이 유지되는 기간 동안 그러나 회원탈퇴 후에도 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 일정 기간 동안 회원정보를 보관합니다. 표시/광고에 관한 기록 : 6개월 (전자상거래 등에서의 소비자보호에 관한 법률) 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률) 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래 등에서의 소비자보호에 관한 법률)</p>
									<h5>4. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h5>
									<p>회사는 이용자들에게 맞춤형 서비스를 제공하기 위하여 이용자들의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)‘를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다. -쿠키의 사용 목적 : 이용자들이 방문한 exlm.kr의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 보안접속 여부, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다. -쿠키의 설치/운영 및 거부 : 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 이지스트의 일부 서비스는 이용에 어려움이 있을 수 있습니다. -설정방법 예시(인터넷 익스플로어) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 > 고급 > 쿠키 허용 여부를 선택</p>
									<h5>5. 개인정보의 파기 절차 및 방법</h5>
									<p>회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다. 1) 파기절차 : 회원님이 회원가입 혹은 멤버십 가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다. 2) 파기방법 : 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</p>
									<h5></h5>
									<p>보존 항목 : 성명, 생년월일(필요시), 휴대폰 번호, 소셜 로그인한 ID(이메일 주소)
보존 이유 : 교육 이력 관리
보존 기간 : 학습자 회원 탈퇴 시에서 1년 후까지</p>
									<h5>6. 개인정보의 안전성 확보</h5>
									<p>회사는 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다. 1) 개인정보 취급 직원의 최소화 및 교육 : 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다. 2) 정기적인 자체 감사 실시 : 개인정보 취급 관련 안전성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다. 3) 내부관리계획의 수립 및 시행 : 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다. 4) 개인정보의 암호화 : 이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알 수 있으며, 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다. 5) 해킹 등에 대비한 기술적 대책 : 이지스트는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신 및 점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</p>
									<h5>7. 개인정보 제공의 제 3자 제공 및 공유</h5>
									<p>회사는 아래의 경우를 제외하고, 이용자의 개인정보를 원칙적으로 제 3자에게 제공하지 않습니다. 1) 이용자들에게 사전에 공개 또는 제 3자 제공에 동의한 경우 2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</p>
									<h5>8. 수집한 개인정보의 위탁</h5>
									<p>8. 수집한 개인정보의 위탁 회사는 고객님의 동의 없이 고객님의 정보를 외부 업체에 위탁하지 않으며, 서비스 이용을 위해 회원이 직접 동의한 경우에만 정보 제공이 진행됩니다. 한국인공지능아카데미는 결제 대행사인 NHN한국사이버결제(주)에 카드 결제 처리를 위탁하기 위해 카드 정보와 생년월일을 제공합니다. 회원이 [멤버십 등록](혹은 [강의 신청])으로 표시되는 멤버십 등록 버튼을 자발적으로 클릭하였을 시, 위탁 사항에 대해 회원이 동의했음을 인지하고 개인정보가 제공됩니다. 전자상거래 등에서 소비자 보호에 관한 법률에 따라 전자금융의 기록은 5년 동안 보존됩니다.</p>
									<p>위탁업체 : NHN한국사이버결제(주)</p>
									<p>웹사이트 : https://www.kcp.co.kr/</p>
									<h5>9. 이용자 및 법정대리인의 권리와 그 행사방법</h5>
									<p>이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다. -이용자 혹은 만 14세 미만 아동의 개인정보 조회와 수정을 위해서는 ‘개인 정보 변경‘(또는 ‘회원정보수정’ 등)을, 가입해지(동의철회)를 위해서는 “회원탈퇴“를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 또는 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다. -귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제 3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다. -회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간“에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>
									<h5>10. 회원의 의무</h5>
									<p>회원님은 자신의 개인정보를 보호할 의무가 있으며, 회원님 본인의 부주의나 인터넷 상의 문제 등으로 개인정보가 유출되어 발생한 문제에 대하여 회사는 일체의 책임을 지지 않습니다.
회원님의 개인정보를 최신의 상태로 정확하게 입력하시기 바랍니다. 회원님의 부정확한 정보입력으로 발생하는 문제의 책임은 회원님 자신에게 있으며, 타인의 이메일 주소나 연락처 혹은 생년월일(필요시) 등 개인정보를 도용하여 서비스 이용 시 회원자격 상실과 함께 주민등록법 및 개인정보보호법에 의거하여 처벌될 수 있습니다.
회원님은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 소셜로그인한 아이디(이메일 주소)를 포함한 회원님의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오.</p>
									<p>회원님은 『정보통신망이용촉진및정보보호등에관한법률』, 개인정보보호법, 주민등록법 등 기타 개인정보에 관한 법률을 준수하여야 합니다.</p>
									<h5>11. 링크 사이트</h5>
									<p>회사는 회원님께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부의 웹사이트 및 자료에 대한 아무런 통제권이 없으므로, 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 통하여 타 웹사이트의 페이지로 옮겨갈 경우 해당 웹사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 웹사이트의 정책을 검토해보시기 바랍니다.</p>
									<h5>12. 개인정보에 관한 민원 서비스</h5>
									<p>회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보관리책임자를 지정하고 있습니다.</p>
									<p>- 개인정보관리책임자 성명: 문아라</p>
									<p>- 전화: 02-2039-0677  / E-mail: ai@ai-academy.ai</p>
									<p>귀하께서는 회사의 서비스를 이용하며 발생하시는 모든 개인정보보호관련 민원을 개인정보관리 책임자에게 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
									<p>1.개인분쟁조정위원회 (http://www.1336.or.kr/1336)</p>
									<p>2.정보보호마크인증위원회 (http://www.eprivacy.or.kr/02-580-0533~4)</p>
									<p>3.대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)</p>
									<p>4.경찰청 사이버테러대응센터 (http://www.ctrc.go.kr/02-392-0330)</p>
									<h5>시행일</h5>
									<p>본 개인정보취급방침은 2022년 01월 01일부터 적용됩니다.</p>
									<h5>부칙</h5>
									<p>이용약관 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 3일 전부터 exlm.kr 사이트의 공지사항을 통해 고지합니다. 사전에 고지가 없었을 경우 공지일자(공고일자)로부터 3일 후부터 효력이 발생합니다.</p>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<!-- /Help Details -->
        
        <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
</template>
