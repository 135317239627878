<template>
  <ul class="nav header-navbar-rht" v-if="currentUser">
    <li class="nav-item user-nav"> 
      <router-link class="dropdown-item" to="course-student"
        ><i class="feather-book me-1"></i> 내 강의</router-link
      >
      
    </li>
    <li class="nav-item user-nav"> 
      <a
        href="javascript:void(0)"
        class="dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        <span class="user-img">
          <img src="../assets/img/icon/profile.png" alt="" />
          <span class="status online"></span>
        </span>
      </a>
      <div
        class="users dropdown-menu dropdown-menu-right"
        data-popper-placement="bottom-end"
      >
        <div class="user-header">
          <!-- <div class="avatar avatar-sm">
            <img
              src="../assets/img/user/user11.jpg"
              alt="User Image"
              class="avatar-img rounded-circle"
            />
          </div> -->
          <div class="user-text">
            <h6 v-if="currentUser.name">{{currentUser.name}}</h6>
            <h6 v-else>{{currentUser.username}}</h6>
            <!-- <p class="text-muted mb-0">Student</p> -->
          </div>
        </div>
        <!-- <router-link class="dropdown-item" to="setting-edit-profile"
          ><i class="feather-user me-1"></i> 프로필</router-link
        > -->

        <router-link class="dropdown-item" to="course-student"
          ><i class="feather-book me-1"></i> 내 강의</router-link
        >
        <router-link 
          class="dropdown-item" to="product-student"
          ><i class="feather-book me-1"></i> 내 모델</router-link
        >
        <router-link class="dropdown-item" to="setting-edit-profile"
          ><i class="feather-user me-1"></i> 계정 설정</router-link
        >
        <!-- <router-link class="dropdown-item" to="course-message"
          ><i class="feather-user me-1"></i> ExLM 에이전트</router-link
        > -->

        <!-- <router-link class="dropdown-item" to="setting-student-subscription"
          ><i class="feather-star me-1"></i> Subscription</router-link
        > -->
        <!-- <div class="dropdown-item night-mode">
          <span><i class="feather-moon me-1"></i> Night Mode </span>
          <div class="form-check form-switch check-on m-0">
            <input class="form-check-input" type="checkbox" id="night-mode" />
          </div>
        </div> -->
        <a class="dropdown-item" @click.prevent="logOut"><i class="feather-log-out me-1"></i> 로그아웃</a>
      </div>
    </li>
  </ul>
</template>
<script>
import Vue from "vue";
export default {
  components: {},
  computed: {
    currentUser() {
      return this.$store.state.auth.user?.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if(this.loggedIn) {
      this.$store.dispatch('auth/check_auth').catch((error) => {
      })
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout').then(
        () => {
          this.$router.push('/');
        })
    }
  }
};
</script>
