<template>
  <!-- Home Banner -->
  <section class="home-slide d-flex align-items-center">
    <Carousel :autoplay="5000" :wrap-around="true" :transition="2000">
      <Slide
        v-for="item, idx in dashboard"
        :key="item.id"
        class="owl-carousel mentoring-course owl-theme"
        style="margin-bottom: 10px;"
      >
        <div class="carousel__item">
          <img 
            v-if="windowWidth > 767" :src="loadImg(item.img)" class="img-fluid" :width="windowWidth">
          <img 
            v-else :src="loadImg(item.m_img)" class="img-fluid"  :width="windowWidth">
          
          <a 
              v-if="item.path"
              :href="item.path"
              target="_blank"
              class="btn main-banner-button" 
              :class="item.theme == 'dark'? 'btn-outline-warning' : 'btn-outline-black'"
              type="button">자세히 보기</a>
        </div>
      </Slide>
      <template #addons>
        <Pagination />
      </template>
    </Carousel>

    
  </section>
</template>
<script>

import dashboard from "../../../assets/json/dashboard.json";
import AOS from "aos";
import { ref, onMounted, onUnmounted, watchEffect } from 'vue';

import { Carousel, Pagination, Slide } from "vue3-carousel";
const images = require.context("../../../assets/img", false, /\.png$|\.jpg$|\.jpeg$/);
import "vue3-carousel/dist/carousel.css";

export default {
  name: "Autoplay",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      dashboard: [{
          "id": 1,
          "img": "main-banner-01.jpeg",
          "m_img": "m-main-banner-01.jpeg",
          "theme": '',
          "path": ""
      },
      {
          "id": 2,
          "img": "main-banner-02.jpeg",
          "m_img": "m-main-banner-02.jpeg",
          "theme": '',
          "path": "https://www.exlm.kr/productlistindex"
      
      },
      {
          "id": 3,
          "img": "main-banner-03.jpeg",
          "m_img": "m-main-banner-03.jpeg",
          "theme": 'dark',
          "path": "http://www.exlm.kr/courselistindex"
      
      },
      {
          "id": 4,
          "img": "main-banner-04.jpeg",
          "m_img": "m-main-banner-04.jpeg",
          "theme": '',
          "path": "https://www.youtube.com/playlist?list=PLhZpGghaa_0QhRZBk6ALlTDM5MlM66Hqj"
      
      }
    ],
    };
  },
  computed: {
    courses() {
      return this.$store.state.course.courses;
    },
  },
  methods: {
    loadImg(imgPath) {
      return images('./' + imgPath).default
    },
  },
  setup() {
    const windowWidth = ref(window.innerWidth);
    // resize 이벤트 핸들러
    const updateWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      windowWidth
    };
  },
  mounted() {
    if ($(".main-wrapper .aos").length > 0) {
      AOS.init({
        duration: 1200,
        once: true,
      });
    }
  },
};
</script>
